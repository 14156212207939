<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/calendarios"
  >
    <h4 class="text-center">Dados básicos</h4>
    <LineDivider></LineDivider>
    <FormField
      label="Nome do Calendário"
      v-model="form.nome"
      :validation="$v.form.nome"
    />
    <FormField
      label="Quantidade de datas exibidas para o cliente"
      v-model="form.qtdDataExibidas"
      :validation="$v.form.qtdDataExibidas"
    />
    <FormField
      label="Horário máximo de atendimento"
      v-model="form.horarioMaxAtendimento"
      :validation="$v.form.horarioMaxAtendimento"
      typeField="time"
    />
    <h4 class="text-center">Cadastro de Número de Entregas Diário</h4>
    <LineDivider></LineDivider>
    <v-row>
      <v-col cols="12" md="6">
        <FormSelect
          label="Dia da Semana"
          v-model="diasSelecionados"
          :options="diasSemana"
          :multiple="true"
        />
      </v-col>
      <v-col cols="12" md="6">
        <FormSelect
          label="Meses"
          v-model="mesesSelecionados"
          :options="mesesDoAno"
          :multiple="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5">
        <FormSelect
          label="Status"
          v-model="statusEntrega"
          :options="entregas"
        />
      </v-col>
      <v-col cols="12" md="5">
        <FormField
          label="Número de Atendimentos"
          v-model="entregasNoDia"
          typeField="number"
        />
      </v-col>
      <v-col cols="12" md="2" class="text-center">
        <FormButton text="Aplicar" @click="criarDatasMassivas" />
      </v-col>
    </v-row>
    <Calendar
      ref="calendar"
      :data-source="dataSource"
      :enable-range-selection="enableRangeSelection"
      @click-day="clickMouseDay"
      @mouse-on-day="handleMouseOver"
    >
    </Calendar>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>

    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="pa-4">
          <span class="text-h5"
            >Editar dados do dia: {{ formateDate(diaSelecionadoDialog) }}</span
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12">
              <FormSelect
                label="Status"
                v-model="statusEntregaDialog"
                :options="entregas"
              />
            </v-col>
            <v-col cols="12">
              <FormField
                label="Número de Atendimentos"
                v-model="entregasNoDiaDialog"
                typeField="number"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <FormButton text="Fechar" type="error" @click="dialog = false" />
          <FormButton text="Salvar" @click="salvarDia" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import { mapGetters } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import {
  CALENDARIO_CREATE,
  CALENDARIO_UPDATE,
  CALENDARIO_LOAD,
  DIAS,
  MESES,
  MESESEMPORTUGUES,
  DIASSEMANAEMPORTUGUES
} from '@/store/actions/calendario'
import Calendar from 'v-year-calendar'

export default {
  name: 'CalendarioForm',
  components: {
    SidePopup,
    LineDivider,
    FormField,
    FormButton,
    FormSelect,
    Calendar
  },
  data: () => ({
    enableRangeSelection: false,
    dataSource: [],
    mode: 'add',
    isLoading: false,
    dialog: false,
    statusEntregaDialog: 'true',
    entregasNoDiaDialog: 20,
    diaSelecionadoDialog: null,
    form: {
      qtdDataExibidas: '20',
      horarioMaxAtendimento: '18:00'
    },
    statusEntrega: 'true',
    entregas: [
      { value: 'true', text: 'Entregarei' },
      { value: 'false', text: 'Não entregarei' }
    ],
    entregasNoDia: 20,
    diasSemana: Object.entries(DIAS).map(([text, value]) => ({ text, value })),
    diasSelecionados: [],
    mesesDoAno: Object.entries(MESES).map(([text, value]) => ({ text, value })),
    mesesSelecionados: []
  }),
  validations: {
    form: {
      nome: { required },
      qtdDataExibidas: { required, numeric },
      horarioMaxAtendimento: { required }
    }
  },
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/calendarios')
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      let calendario = {
        ...this.form
      }
      const dataTransporteDisponivel = {}
      this.dataSource.forEach(objeto => {
        if (objeto.startDate) {
          const dataFormatada = objeto.startDate.toISOString().split('T')[0]
          if (!dataTransporteDisponivel[dataFormatada]) {
            dataTransporteDisponivel[dataFormatada] = {
              entregasNoDia: 0
            }
          }
          dataTransporteDisponivel[dataFormatada].entregasNoDia +=
            objeto.nEntregas
        }
      })
      calendario.dataTransporteDisponivel = dataTransporteDisponivel
      calendario.horarioMaxAtendimento.split(':').length == 2
        ? (calendario.horarioMaxAtendimento += ':00')
        : ''
      if (this.mode == 'edit' && calendario.idcalendarioentrega) {
        this.$store
          .dispatch(CALENDARIO_UPDATE, calendario)
          .then(() => {
            this.$vueOnToast.pop('success', 'Calendário atualizado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch(CALENDARIO_CREATE, calendario)
          .then(() => {
            this.$vueOnToast.pop('success', 'Calendário criado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      }
    },
    getData(idcalendarioentrega) {
      this.isLoading = true
      this.$store
        .dispatch(CALENDARIO_LOAD, idcalendarioentrega)
        .then(data => {
          const {
            nome_calendario,
            qtd_datas_exibidas,
            horario_max_atendimento,
            idcalendarioentrega,
            data_transporte_disponivel
          } = data[0]

          for (const data in data_transporte_disponivel) {
            const entregasNoDia = data_transporte_disponivel[data].entregasNoDia
            const cor = entregasNoDia > 0 ? '#12558C' : '#FF0000'
            const nome = entregasNoDia > 0 ? 'Entregarei' : 'Não entregarei'
            const dataObj = new Date(`${data}T03:00:00.000Z`)
            this.dataSource.push({
              color: cor,
              name: nome,
              nEntregas: entregasNoDia,
              startDate: dataObj,
              endDate: dataObj
            })
          }
          this.form = {
            nome: nome_calendario,
            qtdDataExibidas: qtd_datas_exibidas,
            horarioMaxAtendimento: horario_max_atendimento,
            idcalendarioentrega: idcalendarioentrega
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    clickMouseDay(e) {
      this.dialog = true
      this.diaSelecionadoDialog = e.date
    },
    handleMouseOver(e) {
      const selectedEvent = e.events[0]
      if (selectedEvent && selectedEvent.name) {
        this.$vueOnToast.pop(
          'info',
          `${this.formateDate(selectedEvent.startDate)} : ${
            selectedEvent.name
          }, Número de Entregas: ${selectedEvent.nEntregas}`
        )
      }
    },
    formateDate(data) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
      const formatoBrasileiro = new Intl.DateTimeFormat('pt-BR', options)
      return formatoBrasileiro.format(data)
    },
    getDatasDoMes(diaDaSemana, mes) {
      const datas = []
      const primeiroDiaDoMes = new Date(2024, mes, 1)
      const ultimoDiaDoMes = new Date(2024, mes + 1, 0)
      for (
        let dia = primeiroDiaDoMes.getDate();
        dia <= ultimoDiaDoMes.getDate();
        dia++
      ) {
        const data = new Date(2024, mes, dia)
        if (data.getDay() === diaDaSemana) {
          datas.push(data)
        }
      }
      return datas
    },
    criarObjetosDataSource(diaDaSemana, mes) {
      const datas = this.getDatasDoMes(diaDaSemana, mes)
      const cor = this.statusEntrega == 'true' ? '#12558C' : '#FF0000'
      datas.forEach(data => {
        this.verificaObjeto(data)
        const objeto = {
          color: cor,
          name: this.entregas.find(
            entrega => entrega.value === this.statusEntrega
          ).text,
          nEntregas: this.statusEntrega == 'true' ? this.entregasNoDia : 0,
          startDate: data,
          endDate: data
        }
        this.dataSource.push(objeto)
      })
    },
    criarDatasMassivas() {
      let msg = ''
      this.diasSelecionados.length == 0 ? (msg = ' Dias da Semana,') : ''
      this.mesesSelecionados.length == 0 ? (msg += ' Meses,') : ''
      !this.entregasNoDia || this.entregasNoDia == 0
        ? (msg += ' Número de Atendimentos,')
        : ''
      if (msg != '') {
        this.$vueOnToast.pop('error', `Os campos${msg} não foram preenchidos!`)
        return
      }
      this.mesesSelecionados.forEach(mes => {
        this.diasSelecionados.forEach(diaDaSemana => {
          this.criarObjetosDataSource(diaDaSemana, mes)
        })
      })
    },
    salvarDia() {
      this.verificaObjeto(this.diaSelecionadoDialog)
      const cor = this.statusEntregaDialog == 'true' ? '#12558C' : '#FF0000'
      const objeto = {
        color: cor,
        name: this.entregas.find(
          entrega => entrega.value === this.statusEntregaDialog
        ).text,
        nEntregas:
          this.statusEntregaDialog == 'true' ? this.entregasNoDiaDialog : 0,
        startDate: this.diaSelecionadoDialog,
        endDate: this.diaSelecionadoDialog
      }
      this.dataSource.push(objeto)
      this.dialog = false
    },
    verificaObjeto(data) {
      const indiceObjetoExistente = this.dataSource.findIndex(
        objeto =>
          objeto.startDate &&
          objeto.startDate.toISOString() === data.toISOString()
      )
      if (indiceObjetoExistente !== -1) {
        this.$set(this.dataSource, indiceObjetoExistente, {})
      }
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    title() {
      return this.mode == 'edit' ? 'Editar calendário' : 'Novo calendário'
    }
  },
  created() {
    let { idcalendarioentrega } = this.$route.params
    if (idcalendarioentrega !== 'novo') {
      this.mode = 'edit'
      this.getData(idcalendarioentrega)
    }
  },
  mounted() {
    const calendarElement = this.$refs.calendar.$el
    calendarElement
      .querySelectorAll('.month .month-title')
      .forEach((monthElement, index) => {
        monthElement.innerText = MESESEMPORTUGUES[index]
      })
    calendarElement
      .querySelectorAll('.day-header')
      .forEach((dayHeaderElement, index) => {
        dayHeaderElement.innerText = DIASSEMANAEMPORTUGUES[index % 7]
      })
  },
  watch: {}
}
</script>

<style lang="scss">
.calendar-container {
  background-color: #12558c;
  color: white;
  padding: 20px;
  border-radius: 8px;
}
</style>
